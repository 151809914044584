import styled from 'styled-components';
import { deviceLessThan } from '../../styles/breakpoints';

export const CardContainer = styled.div`
    border-radius: 6px;
    box-shadow: 0 0 13px 1px rgba(0,0,0,0.1);
    padding: 16px;
    text-align:${props => props.align};
    background: white;
    height: 100%;
    @media ${deviceLessThan.tablet}{
        text-align:unset;
        box-shadow:0 2px 8px 1px rgba(0, 0, 0, 0.07);
        padding: 10px;
    }
`;

export const InvestorContainer = styled.div`
    width:75%;
    max-width: 1200px;
    padding:44px 0px 0px;
    margin:0 auto;
    position:relative;
    &::before{
        content:"";
        background:url(${props => props.backgroundBefore}) no-repeat;
        position:absolute;
        left:-16%;
        top:25px;
        background-size: 70px 300px;
        width: 70px;
        height: 300px;
    };
    @media ${deviceLessThan.laptop}{
        width:95%;
    }
    @media ${deviceLessThan.tablet}{
        padding:40px 0px 0px;
        width:100%;
        &::before{
            content:"";
            background:url(${props => props.backgroundBefore}) no-repeat;
            position:absolute;
            right:0;
            left:unset;
            top:40%;
            background-size: 15px 107px;
            width: 15px;
            height: 107px;
        };
    }
`;

export const CardProfile = styled.div`
    display:flex;
    flex-direction:${props => props.flexDirection};
    @media ${deviceLessThan.tablet}{
        flex-direction:${props => props.sFlexDirection};
        border-bottom:${props => props.border};
        padding:${props => props.sPadding};
    }
`;

export const ProfileWrapper = styled.div`
    align-self:center;
    padding: ${props => props.padding ? props.padding : "0px 0px 0px 12px"};
    @media ${deviceLessThan.tablet}{
        padding:${props => props.sPadding};
    }
`;

export const CardWrapper = styled.div`
    flex: ${props=> props.flex ? props.flex: '0 50%'};
    box-sizing:border-box;
    width: 100%;
    padding:${props=> props.padding};
    @media ${deviceLessThan.tablet}{
        padding:${props => props.sPadding};
    }
`;

export const CardsContainer = styled.div`
    width:${props => props.width};
    max-width: ${props => props.maxWidth};
    justify-content:${props => props.justifyContent};
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media ${deviceLessThan.laptop}{
        width:${props => props.mWidth}
    }
    @media ${deviceLessThan.tablet}{
        display: unset;
    }
`;

export const SocialLinkWrapper = styled.div`
    display:flex;
`;

export const AboutUsNavBar = styled.div`
    box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.14);
    border:1px solid rgba(212, 210, 210, 0.06);
    margin:-20px auto 0px;
    position:fixed;
    width:100%;
    z-index:1;
    background:#fff;
    @media ${deviceLessThan.mobileXL}{
        overflow-x:scroll;
    }
`;

export const AboutUsNavWrapper = styled.div`
    display:flex;
    width:75%;
    max-width: 1200px;
    margin:0 auto;
    @media ${deviceLessThan.laptopS}{
        width:95%;
    }
    @media ${deviceLessThan.tablet}{
        justify-content:space-between;
    }
    @media ${deviceLessThan.mobileXL}{
        width:max-content;
    }
`;

export const AboutUsNav = styled.div`
    padding:15px 34px 15px 0px;
    font-weight:${props => props.weight};
    cursor:pointer;
    @media ${deviceLessThan.tablet}{
        padding:10px 1px;
    }
    @media ${deviceLessThan.mobileXL}{
        padding:10px 8px;
    }
`;

export const MissionContainer = styled.div`
    max-width:542px;
    margin:0 auto;
    text-align:center;
    &::before{
        content:"";
        background:url(${props => props.backgroundBefore}) no-repeat;
        position:absolute;
        left:0px;
        top:0px;
        background-size: 416px 498px;
        width: 416px;
        height: 498px;
    };
    &::after{
        content:"";
        background:url(${props => props.backgroundAfter}) no-repeat;
        position:absolute;
        right: ${props => props.afterRight};
        top: ${props => props.afterTop};
        background-size: ${props => props.afterBackgroundSize};
        width: ${props => props.afterWidth};
        height: ${props => props.afterHeight};
    };
    @media ${deviceLessThan.tablet}{
        margin:${props => props.sMargin};
        &::before{
            content:"";
            background:${props => props.sBackgroundBefore};
            width: 421px;
            position: absolute;
            height: 421px;
            opacity: 0.05;
            border-radius: 50%;
            top: 212px;
            left: -100px;
            z-index: -1;
        };
        &::after{
            content:"";
            background:unset;
        };
    }
`;

export const FounderContainer = styled.div`
    &::before{
        content:"";
        background:url(${props => props.backgroundBefore}) no-repeat;
        position: absolute;
        left: 0px;
        top: 790px;
        background-size: 300px 135px;
        width: 300px;
        height: 135px;
        z-index: -1;
    }
    &::after{
        content:"";
        background:url(${props => props.backgroundAfter}) no-repeat;
        position: absolute;
        right: 0px;
        top: 340px;
        background-size: 59px 300px;
        width: 59px;
        height: 300px;
        z-index: -1;
    }
    @media ${deviceLessThan.tablet}{
        &::before{
            content:"";
            background:unset;
        }
        &::after{
            content:"";
            background:unset;
        }
    }
`;

export const AwardContainer = styled.div`
    background:#f2f8fe;
    margin:47px 0px 0px;
    padding:61px 0px 36px;
    @media ${deviceLessThan.tablet}{
        margin:40px 0px 0px;
        padding:33px 0px 33px;
    }
`;

export const AdvisorContainer = styled.div`
    background:#f2f8fe;
    padding:37px 0px 52px;
    @media ${deviceLessThan.tablet}{
        padding:30px 0px 30px;
    }
`;

export const PositionWrapper = styled.div`
    display:inline-flex;
    width:max-content;
    height:18px;
    padding:0px 5px 0px 0px;
    border-radius: 2px;
    box-sizing:border-box;
    background-image:linear-gradient(to bottom, #fcb136, #e39f2f)
`;
