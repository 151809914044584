import React from 'react';
import AboutUs from '../components/AboutUs';
import { graphql } from 'gatsby';
import { getJsonFromString } from '../utils/helpers';

export const query = graphql`
        query{
            content: allGhostPage (
                filter: {
                    tags : { elemMatch : { name: { eq: "#about" }}}
                }
            ){
     		edges{
                node{
                    plaintext
                    meta_title
                    meta_description
                }
            }
        }
    }
`;


const About = props => {
    const content = getJsonFromString(props.data.content.edges[0]?.node.plaintext);
    const title = props.data.content.edges[0]?.node.meta_title;
    const description = props.data.content.edges[0]?.node.meta_description;

    return (
        <AboutUs
            content={content}
            title={title}
            description={description}
            location={props.location}
        />
    )
}
export default About;
