import React, { Fragment, useEffect, useRef, useState } from 'react';
import Layout from '../common/Layout';
import Heading from '../common/ui/Heading';
import { ImageContainer, Image } from '../common/ui/Image';
import Text from '../common/ui/Text';
import MetaData from '../common/meta/MetaData';
import Card from './Card';
import {
    CardsContainer,
    InvestorContainer,
    AboutUsNavBar,
    AboutUsNavWrapper,
    AboutUsNav,
    MissionContainer,
    AwardContainer,
    FounderContainer
} from './Component';


const AboutUs = ({ title, description, keywords, location, content = {} }) => {
    const [active, setActive] = useState(null);
    const about = useRef(null);
    const founders = useRef(null);
    const awards = useRef(null);
    const investors = useRef(null);

    useEffect(() => {
        verticalSmoothScroll(about, "about");
    }, []);

    const verticalSmoothScroll = (name, section) => {
        let offsetTop = name.current.offsetTop;
        window.scrollTo(0, offsetTop - 180);
        setActive(section);
    };

    return (
        <Fragment>
            <MetaData
                data={{}}
                title={title}
                description={description}
                keywords={keywords}
                location={location}
                type="website"
            />
            <Layout>
                <AboutUsNavBar>
                    <AboutUsNavWrapper>
                        <AboutUsNav
                            weight={"about" == active ? "bold" : "normal"}
                            onClick={verticalSmoothScroll.bind(null, about, "about")}
                        >
                            About Us
                        </AboutUsNav>
                        <AboutUsNav
                            weight={"founders" == active ? "bold" : "normal"}
                            onClick={verticalSmoothScroll.bind(null, founders, "founders")}
                        >
                            Founding Team
                         </AboutUsNav>
                        <AboutUsNav
                            weight={"awards" == active ? "bold" : "normal"}
                            onClick={verticalSmoothScroll.bind(null, awards, "awards")}
                        >
                            Awards &amp; Recognition
                        </AboutUsNav>
                        <AboutUsNav
                            weight={"investors" == active ? "bold" : "normal"}
                            onClick={verticalSmoothScroll.bind(null, investors, "investors")}
                        >
                            Our Investors
                         </AboutUsNav>
                    </AboutUsNavWrapper>
                </AboutUsNavBar>
                <MissionContainer
                    sMargin="70px auto 0px"
                    background="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575434/chqbookWeb/AboutUs/dot-pattern_3x.png"
                    backgroundBefore="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575412/chqbookWeb/AboutUs/hero-oval_3x.png"
                    backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575511/chqbookWeb/AboutUs/hexagon-fill_3x.png"
                    afterRight="2%"
                    afterTop="200px"
                    afterBackgroundSize="79px 79px"
                    afterWidth="79px"
                    afterHeight="79px"
                    sBackgroundBefore="linear-gradient(to bottom, #0285d3, #02aae9)"
                    ref={about}
                >
                    <Heading
                        size="sm"
                        weight="medium"
                        color="BLACK"
                        margin="95px 0px 20px"
                        sMargin="0px 0px 15px"
                    >
                        {content.aboutUs?.heading}
                    </Heading>
                    {
                        content.aboutUs &&
                        content.aboutUs.pointers instanceof Array &&
                        content.aboutUs.pointers.map((item, i) => {
                            return <Text
                                size="xmd"
                                weight="medium"
                                color="MEDIUM_BLACK"
                                lineHeight="1.73"
                                opacity="0.8"
                                sPadding="0px 20px"
                                align='left'
                                margin='0 0 10px 0'
                                sMargin='0 0 10px 0'
                                sAlign='center'
                                key={i}
                            >
                                {item}
                            </Text>
                        })
                    }

                </MissionContainer>
                {
                    content.info instanceof Array &&
                    content.info.map((item, i) => {
                        return (
                            <MissionContainer
                                key={i}
                                backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575403/chqbookWeb/AboutUs/hexagon-no-fill_3x.png"
                                afterRight="8%"
                                afterTop="270px"
                                afterBackgroundSize="204px 204px"
                                afterWidth="204px"
                                afterHeight="204px"
                            >
                                <Heading
                                    size="sm"
                                    weight="medium"
                                    color="MEDIUM_BLACK"
                                    margin="50px 0px 20px"
                                    sMargin="65px 0px 15px"
                                >
                                    {item.heading}
                                </Heading>
                                {

                                    item.pointers instanceof Array &&
                                    item.pointers.map((val, j) => {
                                        return <Text
                                            size="xmd"
                                            weight="medium"
                                            color="MEDIUM_BLACK"
                                            lineHeight="1.73"
                                            opacity="0.8"
                                            sPadding="0px 20px"
                                            // align='left'
                                            align={i==0 ? 'center' : 'left'}
                                            margin='0 0 10px 0'
                                            sMargin='0 0 10px 0'
                                            sAlign='center'
                                            key={j}
                                        >
                                            {val}
                                        </Text>
                                    })
                                }
                            </MissionContainer>
                        );
                    })
                }

                <FounderContainer
                    backgroundBefore="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575434/chqbookWeb/AboutUs/dot-pattern_3x.png"
                    backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575448/chqbookWeb/AboutUs/dot-pattern-hero_3x.png"
                    ref={founders}
                >
                    <Heading
                        size="sm"
                        weight="medium"
                        color="MEDIUM_BLACK"
                        margin="60px 0px 30px"
                        sMargin="60px 20px 26px"
                        align="center"
                    >
                        {content.founders?.heading}
                    </Heading>
                    <CardsContainer
                        width="75%"
                        mWidth="95%"
                        maxWidth='1200px'
                    >
                        {content.founders &&
                            content.founders.pointers instanceof Array &&
                            content.founders.pointers.map((el, i) => {
                                return <Card
                                    {...el}
                                    sMainImageWidth="70px"
                                    mainImageWidth="84px"
                                    sPadding="0px 15px 10px"
                                    padding="0px 15px 30px 0px"
                                    key={i} />
                            })}
                    </CardsContainer>
                </FounderContainer>
                <AwardContainer
                    ref={awards}
                >
                    <Heading
                        size="sm"
                        weight="medium"
                        color="MEDIUM_BLACK"
                        padding="0px 0px 30px"
                        align="center"
                        sPadding="0px 0px 26px"
                    >
                        {content.awards.heading}
                    </Heading>
                    <CardsContainer
                        width="75%"
                        mWidth="95%"
                        maxWidth='1200px'
                        justifyContent="center"
                    >
                        {content.awards.pointers.map((el, i) => {
                            return <Card
                                padding="0px 15px 30px 0px"
                                sPadding="0px 18px 10px"
                                borderPadding="0px 0px 10px"
                                border="2px solid #d8d8d86e"
                                profileWrapperPadding="15px 0px 0px 0px"
                                sProfileWrapperPadding="0px 0px 0px 20px"
                                profileDirection="column"
                                sProfileDirection="row"
                                imageContainerMargin="0 auto"
                                sImageContainerMargin="unset"
                                textAlign="center"
                                {...el}
                                key={i}
                            />
                        })
                        }
                    </CardsContainer>
                </AwardContainer>
                <InvestorContainer
                    backgroundBefore="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575448/chqbookWeb/AboutUs/dot-pattern-hero_3x.png"
                    ref={investors}
                >
                    <Heading
                        size="sm"
                        weight="medium"
                        color="MEDIUM_BLACK"
                        margin="0px 0px 32px"
                        align="center"
                        sMargin="0px 0px 26px"
                    >
                        {content.investors?.heading}
                    </Heading>
                    <CardsContainer>
                        {
                            content.investors &&
                            content.investors.pointers instanceof Array &&
                            content.investors.pointers.map((el, i, arr) => {
                                return <Card
                                    flex={i == 0 ? '0 100%' : '0 50%'}
                                    padding={i == arr.length - 1 ? "0px 15px 0px 0px" : "0px 15px 30px 0px"}
                                    sPadding="0px 18px 10px"
                                    mainImageWidth="70px"
                                    {...el}
                                    key={i}
                                />
                            })
                        }
                    </CardsContainer>
                </InvestorContainer>
                <ImageContainer
                    width="30%"
                    sWidth="50%"
                    overflow="hidden"
                >
                    <Image
                        src="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575421/chqbookWeb/AboutUs/curved-pattern_3x.png"
                        width="100%"
                        style={{ margin: "-6vw 0px 15px" }}
                    />
                </ImageContainer>
            </Layout>
        </Fragment>
    )
}

export default AboutUs;
