import React from 'react';
import { CardContainer,
    CardProfile,
    ProfileWrapper,
    SocialLinkWrapper,
    CardWrapper,
    PositionWrapper
} from './Component';
import { Image, ImageContainer } from '../common/ui/Image';
import Text from '../common/ui/Text';

const Card = props => (
    <CardWrapper
        flex={props.flex}
        padding={props.padding}
        sPadding={props.sPadding}
    >
    <CardContainer
        align={props.textAlign}
        height={props.cardHeight}
    >
        <CardProfile
            flexDirection={props.profileDirection}
            sFlexDirection={props.sProfileDirection}
            border={props.border}
            sPadding={props.borderPadding}
        >
            <ImageContainer
                width={props.mainImageWidth}
                sWidth={props.sMainImageWidth}
                margin={props.imageContainerMargin}
                sMargin={props.sImageContainerMargin}
                height={props.mainImageHeight}
                sHeight={props.sMainImageHeight}
                padding={props.mainImagePadding}
                sPadding={props.sMainImagePadding}
            >
                <Image src={props.mainImage} width="100%" />
            </ImageContainer>
            <ProfileWrapper
                padding={props.profileWrapperPadding}
                sPadding={props.sProfileWrapperPadding}
            >
                <Text
                    size="lg"
                    color="ABOUT_FOUNDER_NAME"
                    weight="bold"
                    display="inline-block"
                    sDisplay="block"
                    padding="0px 15px 0px 0px"
                >
                    {props.displayName}
                </Text>
                {props.designation && <Text
                    size="xs"
                    color="ABOUT_DESCRP"
                    opacity="0.8"
                    height="20px"
                >
                    {props.designation}
                </Text>}
                {props.position && <PositionWrapper>
                    <ImageContainer
                        width="18px"
                        margin="-2px 0px 0px"
                    >
                        <Image src="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603569064/chqbookWeb/0-5kb/winner-star-icon_3x.png" width="100%" />
                    </ImageContainer>
                    <Text
                        size="xxxs"
                        color="ABOUT_DESCRP"
                        opacity="0.8"
                        color="WHITE"
                        margin="-2px 0px 0px"
                        sMargin="-2px 0px 0px"
                    >
                        {props.position}
                    </Text>
                    </PositionWrapper>}
                {(props.linkedin || props.twitter) &&
                <SocialLinkWrapper>
                    <a href={props.linkedin} target="_blank">
                        <ImageContainer
                            width="max-content"
                        >
                            <Image src="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603460286/chqbookWeb/0-5kb/linkedin_3x.png" width="13px" />
                        </ImageContainer>
                    </a>
                    <a href={props.twitter} target="_blank">
                        <ImageContainer
                            width="max-content"
                            margin="0px 0px 0px 10px"
                        >
                            <Image src="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603460293/chqbookWeb/0-5kb/twitter_3x.png" width="14.4px" />
                        </ImageContainer>
                    </a>
                </SocialLinkWrapper>}
            </ProfileWrapper>
        </CardProfile>
        <Text
            size="sm"
            color="ABOUT_DESCRP"
            padding="13px 0px 0px"
        >
            {props.about}
        </Text>
    </CardContainer>
    </CardWrapper>
);

export default Card;
